<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 09:19:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="news-lists">
    <section class="news-banner">
      <img src="../../assets/images/os/newListbanner.png">
    </section>
    <div class="main">
      <div class="left">
        <h3 style="color:#FD9C02">
          新闻资讯
        </h3>
        <div class="lb-news">
          <router-link v-for="(item,index) in newsList" :key="index" class="dg-news" :to="item.linkUrl">
            <img :src="item.imgUrl">
            <div class="news-introduce">
              <h3 class="news-title">
                {{ item.title }}
              </h3>
              <p class="introduce">
                {{ item.dec }}
              </p>
              <h5 class="time">
                发布时间： {{ item.time }}
              </h5>
            </div>
          </router-link>
        </div>
        <div class="pagination">
          <el-pagination
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
            background
            layout=" prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsLists',
  data() {
    return {
      keyWord: '',
      newsList: [],
      // 第一页新闻
      newsList1: [{
        imgUrl: require('../../assets/images/news/newsList/news1-1.png'),
        title: '中共中央政治局常委、国务院副总理韩正来我公司视察',
        dec: '2020 年 6 月 1 日， 中共中央政治局常委、国务院副总理韩正来到湖南新云网科技有限公司视察，了解透明计算成...',
        time: '2020-06-01 ',
        linkUrl: '/indexOs/newsDetails1'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news1-2.png'),
        title: '岳麓山大学科技城：强化科技人才支撑 打造湖南创新高地',
        dec: '在中南大学科技园研发总部，15 位联络员负责对接园区 51 家企业，了解了企业需求，精准服务。湖南新云网是一...',
        time: '2020-06-03 ',
        linkUrl: '/indexOs/newsDetails2'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news1-3.png'),
        title: '大科城软件产业联盟拍了拍你，新云网智慧亮剑！',
        dec: '3月28日，岳麓山大学科技城软件产业生态圈共建计划启动，岳麓山大学科技城软件和信息技术服务业产业联盟...',
        time: '2020-08-03 ',
        linkUrl: '/indexOs/newsDetails3'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news1-4.png'),
        title: '用强有力的奖励政策加速科技成果转化落地',
        dec: '用强有力的奖励政策加速科技成果转化落地，岳麓山国家大学科技城通过盘活人才、技术、资金、市场等资源...',
        time: '2020-08-24 ',
        linkUrl: '/indexOs/newsDetails4'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news1-5.png'),
        title: '爱心点燃希望 真情传递温暖 ——新云网向花垣县双龙镇的十八洞小学进行捐赠',
        dec: '种下一粒种子，收获一片生机；献出一片爱心，托起一份希望。2020年5月，湖南新云网科技有限公司仲夏送暖...',
        time: '2020-05-30 ',
        linkUrl: '/indexOs/newsDetails5'
      }],
      // 第二页新闻
      newsList2: [{
        imgUrl: require('../../assets/images/news/newsList/news2-1.png'),
        title: '我是接班人”网络大课堂反响强烈！近700万人次观看',
        dec: '全省教育大会是湖南省近年来最高规格的一次教育盛会，会上深入学习贯彻习近平总书记关于教育的重要论述和...',
        time: '2019-11-20 ',
        linkUrl: '/indexOs/newsDetails6'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news2-2.png'),
        title: '长沙市这所小学打造智慧校园，极具未来感',
        dec: '今年4月，教育部公布了2019年度“智慧教育示范区”建设项目名单，长沙市成功上榜。并随即加快了教育信息...',
        time: '2019-09-19 ',
        linkUrl: '/indexOs/newsDetails7'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news2-3.png'),
        title: '【壮丽70年·奋斗新时代】星城闪耀“未来之光”',
        dec: '长沙市人均GDP超过13万元，在全国大城市中靠前；长沙市获国家授牌智能制造示范企业和项目27个，居省会城...',
        time: '2019-05-20 ',
        linkUrl: '/indexOs/newsDetails8'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news2-4.png'),
        title: '岳麓山国家大学科技城：23平方公里的产学研高地',
        dec: '承续岳麓千年文脉的岳麓山国家大学科技城有20多所高校院所集聚，这片没有围墙的大学城正在成为自主创新策...',
        time: '2019-05-20 ',
        linkUrl: '/indexOs/newsDetails9'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news2-5.png'),
        title: '两山”崛起“头脑产业” —长沙高质量发展调研行',
        dec: '实现高质量发展，需要形成良好的创新生态，促进各种资源要素协同共生。探究长沙市经济高质量发展，不难发...',
        time: '2019-05-09 ',
        linkUrl: '/indexOs/newsDetails10'
      }],
      // 第三页新闻
      newsList3: [{
        imgUrl: require('../../assets/images/news/newsList/news3-1.png'),
        title: '中央媒体“长沙高质量发展调研行”启动',
        dec: '今天，中宣部组织的中央媒体来湘，“壮丽70年，奋斗新时代，推动高质量发展调研行”主题采访活动正式启动...',
        time: '2019-04-28 ',
        linkUrl: '/indexOs/newsDetails11'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news3-2.png'),
        title: '透明计算应用系列成果再出智慧医疗和智慧教育新品， 5G技术将催生更多应用场景',
        dec: '“透明计算”胶片打印机5秒钟可自助打印出CT片，通过透明计算银医通挂号、缴费、查询方便快捷……两年多...',
        time: '2019-03-31 ',
        linkUrl: '/indexOs/newsDetails12'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news3-3.png'),
        title: '2019互联网岳麓峰会4月1号启幕：“互联网+教育” 让教育教学更智能',
        dec: '去年，湖南获批国家教育信息化2.0试点省，“互联网+教育”极大地促进了教育公平。在长沙就有这样一家企业...',
        time: '2019-03-27 ',
        linkUrl: '/indexOs/newsDetails13'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news3-4.png'),
        title: '推进科研成果转化 培养高素质人才',
        dec: '在2018国家科学技术奖励大会上，湖南的高校有20项成果获得国家科技大奖。湖南各高校积极推进科研成果转化...',
        time: '2019-01-10',
        linkUrl: '/indexOs/newsDetails14'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news3-5.png'),
        title: '打通高校科研与市场“隔断” 服务社会高质量发展',
        dec: '全省教育大会是湖南省近年来最高规格的一次教育盛会，会上深入学习贯彻习近平总书记关于教育的重要论述和...',
        time: '2019-01-09',
        linkUrl: '/indexOs/newsDetails15'
      }],
      // 第四页新闻
      newsList4: [{
        imgUrl: require('../../assets/images/news/newsList/news4-1.png'),
        title: '岳麓山国家大学科技城：有人又有才 科创平台跑起来',
        dec: '',
        time: '2019-05-25',
        linkUrl: '/indexOs/newsDetails16'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news4-2.png'),
        title: '支持“大科城”诚意满满 长沙将补贴“真金白银',
        dec: '',
        time: '2018-08-14',
        linkUrl: '/indexOs/newsDetails17'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news4-3.png'),
        title: '中央媒体“长沙高质量发展调研行”启动',
        dec: '',
        time: '2019-04-28',
        linkUrl: '/indexOs/newsDetails18'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news4-4.png'),
        title: '岳麓山大学科技城:强化科技人才支撑  打造湖南创新新高地',
        dec: '',
        time: '2020-06-03',
        linkUrl: '/indexOs/newsDetails19'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news4-5.png'),
        title: '智慧课堂如何教学-新闻大求真',
        dec: '',
        time: '2019-10-30',
        linkUrl: '/indexOs/newsDetails20'
      }],
      // 第四页新闻
      newsList5: [{
        imgUrl: require('../../assets/images/news/newsList/news5-1.png'),
        title: '着力优化创新生态  塑造更多引领性发展',
        dec: '',
        time: '2020-06-24',
        linkUrl: '/indexOs/newsDetails21'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news5-2.png'),
        title: '长沙迈入“智慧教育”时代 人工智能打造高品质课堂',
        dec: '',
        time: '2019-12-02',
        linkUrl: '/indexOs/newsDetails22'
      }, {
        imgUrl: require('../../assets/images/news/newsList/news5-3.png'),
        title: '移动风云 以“链”促强 “链”接未来',
        dec: '',
        time: '2020-09-08',
        linkUrl: '/indexOs/newsDetails23'
      }],
      // 分页
      pageNum: 1,
      pageSize: 5,
      total: 23
    };
  },
  mounted() {
    this.newsList = this.newsList1;
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.pageNum = val;
      if (val === 1) {
        this.newsList = this.newsList1;
      } else if (val === 2) {
        this.newsList = this.newsList2;
      } else if (val === 3) {
        this.newsList = this.newsList3;
      } else if (val === 4) {
        this.newsList = this.newsList4;
      } else if (val === 5) {
        this.newsList = this.newsList5;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (val === 1) {
        this.newsList = this.newsList1;
      } else if (val === 2) {
        this.newsList = this.newsList2;
      } else if (val === 3) {
        this.newsList = this.newsList3;
      } else if (val === 4) {
        this.newsList = this.newsList4;
      } else if (val === 5) {
        this.newsList = this.newsList5;
      }
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1400px) {
  .news-lists{
    width: 100%;
    height: auto;
    background: #fff;
    .news-banner{
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
      img{
        width: 100%;
        height: auto;
      }
    }
    .main{
      width: 1200px;
      height: auto;
      margin: 0 auto;
      display: flex;
      padding: 58px 0;
      justify-content: space-between;
      .left{
        flex: 1;
        margin-right: 24px;
        h3{
          color: #313131;
          font-size:30px;
          font-weight: 600;
        }
        .lb-news{
          width: 100%;
          .dg-news{
            display: flex;
            padding: 18px 20px;
            img{
              width:324px;
              height:186px;
            }
            .news-introduce{
              flex: 1;
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .news-title{
                font-size:24px;
                font-weight: 600;
                color: #313131;
              }
              .introduce{
                font-size:18px;
                line-height:32px;
              }
              .time{
                font-size:18px;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              background:rgba(247,248,249,1);
              border-radius:10px;
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
      .pagination{
        display: flex;
        justify-content: center;
        margin: 50px 0 150px;
      }
      // 搜索区域
      .search-right{
        width: 334px;
        .el-input{
          width: 329px;
          height: 45px;
        }
        .el-input__inner{
          border-radius: 5px;
        }
        .el-input__icon{
          font-size: 24px;
        }
        .hot-news{
          .hot-title{
            font-size:24px;
            margin-bottom: 12px;
            .hot-icon{
              display: inline-block;
              background: url('../../assets/images/news/hot.png');
              width: 18px;
              height: 22px;
              margin-left: 6px;
            }
          }
          .dg-news{
            display: flex;
            margin-bottom: 22px;
            img{
              width:132px;
              height:76px;
            }
            .news-introduce{
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .news-title{
                font-size:16px;
                font-weight: 600;
                color: #313131;
                box-sizing: border-box;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                overflow: hidden;
              }
              .time{
                font-size:18px;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .news-lists{
    width: 100%;
    height: auto;
    background: #fff;
    .news-banner{
      display: none;
    }
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding: .747rem 0;
      justify-content: space-between;
      .left{
        flex: 1;
        padding: .48rem;
        h3{
          color: #313131;
          font-size:.747rem;
        }
        .lb-news{
          width: 100%;
          .dg-news{
            display: flex;
            padding-top: .48rem;
            img{
              width:3.786rem;
              height:100%;
            }
            .news-introduce{
              flex: 1;
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .news-title{
              font-size:0.373rem;
                color: #313131;
              }
              .introduce{
                display: none;
              }
              .time{
                font-size:.267rem;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              background:rgba(247,248,249,1);
              border-radius:10px;
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
      .pagination{
        display: flex;
        justify-content: center;
        margin: 50px 0;
      }
      // 搜索区域
      .search-right{
        width: 334px;
        .el-input{
          width: 329px;
          height: 45px;
        }
        .el-input__inner{
          border-radius: 5px;
        }
        .el-input__icon{
          font-size: 24px;
        }
        .hot-news{
          .hot-title{
            font-size:24px;
            margin-bottom: 12px;
            .hot-icon{
              display: inline-block;
              background: url('../../assets/images/news/hot.png');
              width: 18px;
              height: 22px;
              margin-left: 6px;
            }
          }
          .dg-news{
            display: flex;
            margin-bottom: 22px;
            img{
              width:132px;
              height:76px;
            }
            .news-introduce{
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .news-title{
                font-size:16px;
                font-weight: 600;
                color: #313131;
                box-sizing: border-box;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                overflow: hidden;
              }
              .time{
                font-size:18px;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
    }
  }
}

</style>
